.root {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 9999;
  --f7-progressbar-height: 2px;
  --f7-progressbar-bg-color: transparent;
}
