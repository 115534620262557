.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: calc(var(--unit-space) * 1.5);
}

.titles {
  h1 {
    margin-top: 0;
    margin-bottom: calc(var(--unit-space) * 1.5);
  }
  h2 {
    line-height: 1.9;
    margin-top: calc(var(--unit-space) * -1.5);
    margin-bottom: calc(var(--unit-space) * 0.2);
  }
}

.chips {
  text-align: right;
  flex-grow: 1;
  margin-top: calc(var(--unit-space) * 0.2);
  margin-right: 2.5px;
  margin-left: calc(var(--unit-space) * 0.5);
  margin-bottom: calc(var(--unit-space) * -1.25);
}
