.root {
  position: relative;
  z-index: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.player {
  position: relative;
  z-index: 1;
}

.chip {
  position: absolute;
  z-index: 2;
  top: calc(var(--unit-space) * 0.75);
  right: calc(var(--unit-space) * 0.7);
  // iOS Safari z-index fix
  -webkit-transform: translate3d(0, 0, 0);
  & > * {
    margin-bottom: 0;
  }
}
