.root {
  :global {
    .iOSPWA-overlay {
      background: var(--palette-alternate-main);
    }
    .iOSPWA-cancel {
      width: auto;
    }
  }
}
