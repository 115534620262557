@import '../../../../node_modules/framework7/css/framework7.bundle.css';

:root {
  --f7-page-bg-color: var(--palette-surface-main);
  --f7-theme-color: var(--palette-primary-main);
  --f7-theme-color-rgb: var(--palette-primary-main-rgb);
  --f7-theme-color-shade: var(--palette-primary-shade);
  --f7-theme-color-tint: var(--palette-primary-tint);

  --f7-block-title-text-color: var(--palette-surface-contrast);
}

body {
  color: var(--palette-surface-contrast);
  background: var(--palette-surface-main);
  font-family: var(--typography-primary);
  font-size: var(--unit-text);
  font-weight: normal;
  line-height: 1.6;
}

.toast-icon {
  font-size: 40px;
  margin-top: -4px;
}

.actions-backdrop {
  background: var(--palette-alternate-main);
}
