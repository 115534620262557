.root {
  width: var(--logo-width);
  height: calc(var(--logo-width) / 3);
  position: relative;
  z-index: 0;
  img {
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.925;
  }
}
