.root {
  margin: 0;
  padding: 0.5em 0.5em 0;
  text-align: center;
  background: none;
}

.chip {
  cursor: pointer;
}
