* {
  &.root {
    opacity: 0;
    transition: opacity var(--fade-element-duration) ease-in-out;
  }
}
.in {
  &.root {
    opacity: 1;
  }
}
