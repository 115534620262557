.root {
  display: flex;
  flex-direction: column;
  padding-top: calc(var(--card-element-padding-y) / 2);
  padding-bottom: calc(var(--card-element-padding-y) / 2);
  padding-left: var(--card-element-padding-x);
  padding-right: var(--card-element-padding-x);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: calc(var(--unit-space) * 1.25);
  color: var(--palette-surface-contrast);
  text-shadow: 0 0 0.25px var(--palette-surface-contrast);
  font-size: calc(var(--unit-text) * 0.95);
}

.chip {
  margin: 0;
  margin-right: -2px;
}

.subtitle {
  color: var(--palette-surface-accent);
  font-size: calc(var(--unit-text) * 0.875);
}
