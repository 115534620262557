.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 4px;
  color: var(--palette-surface-accent);
  font-size: calc(var(--unit-text) * 0.875);
  padding-top: calc(var(--card-element-padding-y) / 2);
  padding-bottom: calc(var(--card-element-padding-y) / 2);
  padding-left: var(--card-element-padding-x);
  padding-right: var(--card-element-padding-x);
  & > * {
    display: block;
  }
}
