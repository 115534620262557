.container {
  margin: 0 auto;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: var(--frame-container-width);
  height: var(--frame-window-height);
}

.app {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  height: var(--frame-window-height);
  box-shadow: var(--shadow-lg);
  width: var(--frame-app-width);
  left: var(--frame-app-left);
}

.info {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--frame-info-width);
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background: var(--palette-primary-main);
    top: 0;
    left: -1000%;
    right: 1000%;
    width: 2100%;
    height: 155px;
  }
  .logo {
    margin: 37.5px 3em 0 auto;
  }
  .box {
    width: 150px;
    margin: 0 calc(var(--unit-space) * 5) calc(var(--unit-space) * 2) auto;
    text-align: center;
  }
  .qr {
    width: 120px;
  }
  p {
    margin: 0.4em 0 0;
    line-height: 1.6;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: calc(var(--unit-text) * 0.75);
    color: var(--palette-surface-contrast);
    text-shadow: 0 0 1px var(--palette-surface-contrast);
  }
}
