.root {
  width: 100%;
  padding-top: calc(calc(360 / 640) * 100%);
  position: relative;
  z-index: 0;
}

.container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
