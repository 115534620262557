@mixin title {
  --title-element-top-unit: var(--unit-space);
  --title-element-bottom-unit: var(--unit-space);

  font-weight: normal;
  line-height: 1.35;
  opacity: 0.925;
  font-family: var(--typography-secondary);
  color: var(--palette-surface-contrast);
  & > * {
    display: block;
  }
}

.large {
  @include title;
  font-size: calc(var(--unit-text) * 2.2);
  margin-top: calc(var(--title-element-top-unit) * 1.5);
  margin-bottom: var(--title-element-bottom-unit);
}

.medium {
  @include title;
  font-size: calc(var(--unit-text) * 1.75);
  margin-top: calc(var(--title-element-top-unit) * 1.25);
  margin-bottom: var(--title-element-bottom-unit);
}

.subtitle {
  @include title;
  font-family: var(--typography-primary);
  font-size: calc(var(--unit-text) * 1);
  margin-top: calc(var(--title-element-top-unit) * -1);
  margin-bottom: calc(var(--title-element-bottom-unit) * 1.25);
}

.section {
  @include title;
  font-size: calc(var(--unit-text) * 1);
  font-weight: 600;
  font-family: var(--typography-primary);
  margin-top: calc(var(--title-element-top-unit) * 1.25);
  margin-bottom: calc(var(--title-element-bottom-unit) * 0.5);
}
