@import '../../../../node_modules/normalize.css/normalize.css';

body {
  margin: 0;
  user-select: none;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: visible;
  @media print {
    background-color: #fff;
  }
}

menu {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

input,
button {
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background: transparent;
  &:focus,
  &:active {
    outline: none;
  }
}

button {
  user-select: none;
  cursor: pointer;
}

iframe {
  border: none;
}
