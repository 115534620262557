* {
  &.root {
    --card-element-padding-x: calc(var(--unit-space) * 1);
    --card-element-padding-y: calc(var(--unit-space) * 0.75);

    display: block;
    cursor: default;
    overflow: hidden;
    background: var(--palette-surface-tint);
    border-radius: calc(var(--unit-radius) * 2);
    box-shadow: var(--shadow-md);
    margin: calc(var(--unit-space) * 0.4) 0;
    padding-top: calc(var(--card-element-padding-y) / 2);
    padding-bottom: calc(var(--card-element-padding-y) / 2);
  }
  .card {
    display: flex;
    flex-direction: column;
  }
}

.link {
  &.root {
    cursor: pointer;
    &:hover {
      background: var(--palette-surface-main);
    }
  }
}
