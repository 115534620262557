.root {
  --block-element-y: var(--unit-space);
  --block-element-x: var(--unit-space);

  display: block;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.separate {
  --block-element-y: calc(var(--unit-space) * 1.75);
}

.xy {
  margin: var(--block-element-y) 0;
  padding: 0 var(--block-element-x);
}

.top {
  margin-top: var(--block-element-y);
}

.right {
  padding-right: var(--block-element-x);
}

.bottom {
  margin-bottom: var(--block-element-y);
}

.left {
  padding-left: var(--block-element-x);
}
